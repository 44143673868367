<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        lg="6"
      >
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
      <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

        <b-form-group
          :label="$t('fields.student_account')"
          label-for="email"
        >
          <v-select
            v-model="linked_users"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            multiple
            label="email"
            :options="dreamer.users"
          />
        </b-form-group>

        <!-- Family Accounts -->
          <template v-for="(fam_acc, idx) in dreamer.family_account">
          <validation-provider
            :key="idx"
            #default="validationContext"
            :name="$t('fields.family_account')"
            rules="email"
          >
            <b-form-group
              :label="`${$t('fields.family_account')} #${idx+1}`"
              :label-for="`family_account[${idx}]`"
            >
              <b-input-group>
                <b-form-input
                  :id="`family_account[${idx}]`"
                  v-model="dreamer.family_account[idx].email"
                  autofocus
                  :state="dreamer.family_account[idx].validated && getValidationState(validationContext)"
                  trim
                  placeholder=""
                />
                <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      style="color: tomato"
                      icon="TrashIcon"
                      @click="deleteFamAcc(idx)"
                    />
                  </b-input-group-append>
                </b-input-group>
                <span style="color:#ea5455; font-size: 0.857rem; width:100%;">
                    {{ !dreamer.family_account[idx].validated ? $t('fields.family_account_error') : ''}}
                  </span>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          </template>
          <div>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="link"
              class="mb-1"
              @click="AddFamilyEmailField"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-75"
              /> {{ $t('fields.add_family_email') }}
            </b-button>
          </div>
 
      <!-- Action Buttons -->
      <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          type="button"
          @click="submitChanges"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        >
          {{ $t('forms.save_changes') }}
        </b-button>
        <b-button
          variant="outline-secondary"
          type="button"
          @click="hasHistory() 
            ? $router.go(-1) 
            : $router.push({ 
              name: 'school-dreamers-view', 
              params: { id: school.id,
                dreamer_id: dreamer.id, } })"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        >
          {{ $t('forms.go_back') }}
        </b-button>

        </b-form>
      </validation-observer>
      </b-col>
      
    </b-row>   
  </div>
</template>

<script>
import {
  BInputGroup, BInputGroupAppend,
  BButton, BRow, BCol, BFormGroup,BFormInvalidFeedback, BFormInput, BForm, BCard, BCardHeader, BCardTitle,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

export default {
  components:{  
    vSelect,
    ValidationProvider, ValidationObserver,
    BInputGroup, BInputGroupAppend,
    BButton, BRow, BCol, BFormGroup,BFormInvalidFeedback, BFormInput, BForm, BCard, BCardHeader, BCardTitle,
  },
  directives: {
    Ripple,
  },
  setup(props) {
    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      // form validation
      refFormObserver,
      getValidationState,
    }
  },
  props:{
    school: {
      type: Object,
      required: true
    },
    dreamer: {
      type: Object,
      required: true,
    },
  },
  data(){
    return {
      required,
      linked_users: []

    }
  },
  mounted(){
    let lu = []
    this.dreamer.users.map(x=> {
      if(x.linked){
        lu.push(x)
      }
    })
    this.linked_users = lu
  },
  methods:{
    hasHistory () { 
       return window.history.length > 2 
    },
    async removeUser(user_id){
      console.log(user_id)
      let data = {
          'dreamer_id': this.dreamer.id,
          'tab': 'user',
          'user_id':user_id
        }
        await this.$http.post('school/dreamer/unlink', data).then( response =>{
            console.log(response)
            if(response.data.status == 200){
              this.makeToast('success', this.$t('Success'), 'User unlinked successfully');
              //this.getData(this.dreamer.id)
            } else{
              this.makeToast('danger', this.$t('Error'), response.data.msg);
            }
        }).catch(error => {
            console.log("Err -> ", error)
        })
    },
    deleteFamAcc(idx){
      if(this.dreamer.family_account[idx].id){
        this.removeUser(this.dreamer.family_account[idx].id);
      }
      this.dreamer.family_account.splice(idx, 1);
    },
    AddFamilyEmailField(){
      this.dreamer.family_account.push({
        email:'',
        validated:true
      })
    },
    async submitChanges(){
      let data = {
          'id': this.dreamer.id,
          'linked_users':this.linked_users,
          'family_account':this.dreamer.family_account,
          'tab':'users',
      } 
      console.log(data)
      await this.$http.post('school/dreamer/edit', data).then( response => {
        console.log(response)
        if(response.data.status == 200){
          this.$emit('refetch-data')
          this.makeToast('success', this.$t('Success'), this.$t('forms.update_success'));
        } else if(response.data.status == 204){
          this.makeToast('danger', this.$t('Error'), 'Please check the family accounts indicated.');
          console.log(response.data)
          this.dreamer.family_account = response.data.family_account
          //this.dreamer.family_account
        } else {
          this.makeToast('danger', this.$t('Error'), response.data.msg);
        }
      }).catch(error => {
        console.log("Err -> ", error);
      })

    },
    makeToast(variant = null, tit ='',msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>