<template>
  <div>
    <validation-observer
      ref="refFormObserver"
    >
      <!-- User Info: Input Fields -->
      <b-form>

        <b-row class="mb-2">
          <b-col>
            <b-avatar
              size="70px"
              class="mr-2"
              :src="require('@/assets/images/avatar/AvatarSprite_' + (dreamer.avatar || '57') + '.png?v4')"

            />
            <b-button
              :variant="!showAvatarOptions?'primary':'danger'"
              @click="showAvatarOptions = !showAvatarOptions"
            >
              {{ showAvatarOptions ? $t('table.hide') : $t('table.change')}}
            </b-button>
          </b-col>
        </b-row>
        <b-row v-if="showAvatarOptions">
          <b-col>
            <avatar-selector
                :avatar_id="dreamer.avatar"
                @set-avatar="setAvatar"
                @toggle-edit-avatar="showAvatarOptions = false"
            ></avatar-selector>
          </b-col>
        </b-row>


        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <!-- Full Name -->
            <validation-provider
              #default="validationContext"
              :name="$t('fields.name')"
              rules="required"
            >
              <b-form-group
                :label="$t('fields.name')"
                label-for="name"
              >
                <b-form-input
                  id="name"
                  v-model="dreamer.name"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>

            </validation-provider>

            <!-- BIRTHDATE -->
            <validation-provider
              #default="validationContext"
              :name="$t('fields.f_nac')"
            >
            <b-form-group
                :label="$t('fields.f_nac')"
                label-for="birthdate"
                :state="getValidationState(validationContext)"
              >
              <b-form-datepicker
                id="birthdate"
                v-model="dreamer.birthdate"
                class="mb-1"
                :locale="getLocal"
              />
            </b-form-group>
            </validation-provider>
          </b-col>
      
          <b-col
            cols="12"
            md="6"
          >
            <!-- REGISTRATION NUMBER -->
            <validation-provider
              #default="validationContext"
              :name="$t('fields.registration_number')"
              rules=""
            >
              <b-form-group>
              <label for="registration_number" class="d-block">
                {{$t('fields.registration_number')}}
                <feather-icon
                  v-b-tooltip.hover.bottom="$t('fields.registration_number_info')"
                  icon="HelpCircleIcon"
                  size="16"
                  class="text-muted cursor-pointer ml-1"
                />
              </label>
                <b-form-input
                  id="registration_number"
                  v-model="dreamer.registration_number"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>

            </validation-provider>

          </b-col>
         </b-row>
      </b-form>
    </validation-observer>

    <!-- Action Buttons -->
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      type="button"
      @click="submitChanges"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
    >
      {{ $t('forms.save_changes') }}
    </b-button>
    <b-button
      variant="outline-secondary"
      type="button"
      
      @click="hasHistory() 
        ? $router.go(-1) 
        : $router.push({ 
          name: 'school-dreamers-view', 
          params: { id: school.id,
            dreamer_id: dreamer.id, } })"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
    >
      {{ $t('forms.go_back') }}
    </b-button>

  </div>
</template>

<script>
import {
 VBTooltip,  BButton, BMedia, BAvatar,BFormDatepicker,  BRow, BCol, BFormGroup,BFormInvalidFeedback, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import AvatarSelector from './AvatarSelector.vue'

export default {
  components:{
    AvatarSelector,
    
    BButton, BMedia, BAvatar,BFormDatepicker,  BRow, BCol, BFormGroup,BFormInvalidFeedback, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox,
    ValidationProvider, ValidationObserver,VBTooltip
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  setup(props) {
    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      // form validation
      refFormObserver,
      getValidationState,
    }
  },
  props:{
    school: {
      type: Object,
      required: true
    },
    dreamer: {
      type: Object,
      required: true,
    },
  },
  data(){
    return {
      required,

      showAvatarOptions:false,

    }
  },
  computed:{
    getLocal(){
      return this.$store.getters['user/getLang']
    },
  },
  methods:{
    hasHistory () { 
       return window.history.length > 2 
    },
    setAvatar(id){
        this.dreamer.avatar = id;
        //this.showAvatarOptions = false
    },
    async submitChanges(){
      console.log(this.dreamer)
      let data = {
          'id': this.dreamer.id,
          'name':this.dreamer.name,
          'avatar':this.dreamer.avatar,
          'birthdate' : this.dreamer.birthdate,
          'registration_number': this.dreamer.registration_number,
          'tab':'main',
      }
      await this.$http.post('school/dreamer/edit', data).then( response => {
        console.log(response)
        if(response.data.status == 200){
          this.$emit('refetch-data')
          this.makeToast('success', this.$t('Success'), this.$t('forms.update_success'));
        } else {
          this.makeToast('danger', this.$t('Error'), response.data.msg);
        }
      }).catch(error => {
        console.log("Err -> ", error);
      })

    },
    makeToast(variant = null, tit ='',msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
  }
}
</script>

<style>

</style>