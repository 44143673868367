<template>
  <div>
    <template v-if="dreamer">
      <custom-breadcrumb
        :pageTitle="$t('forms.edit_model', {model:$t('models.dreamer')})"
        :breadcrumb="breadcrumb"
      ></custom-breadcrumb>
      <b-row>
        <b-col>
          <b-card>
            <b-tabs v-model="tabIndex" v-if="dreamer">
              <b-tab>
                <template #title>
                  <feather-icon
                    icon="InfoIcon"
                    size="16"
                    class="mr-0 mr-sm-50"
                  />
                  <span class="d-none d-sm-inline">{{$t('models.dreamer')}}</span>
                </template>
                <tab-main
                  :school="school"
                  :dreamer="dreamer"
                  @refetch-data="getData(dreamer.id)"
                  class="mt-2 pt-75"
                />
              </b-tab>

              <!-- Users -->
              <b-tab>
                <template #title>
                  <feather-icon
                    icon="UserIcon"
                    size="16"
                    class="mr-0 mr-sm-50"
                  />
                  <span class="d-none d-sm-inline">{{$t('fields.student_account')}}</span>
                </template>
                <tab-users
                  :school="school"
                  :dreamer="dreamer"
                  @refetch-data="getData(dreamer.id)"
                  class="mt-2 pt-75"
                />
              </b-tab>

              <!-- Groups -->
              <b-tab>
                <template #title>
                  <feather-icon
                    icon="SmileIcon"
                    size="16"
                    class="mr-0 mr-sm-50"
                  />
                  <span class="d-none d-sm-inline">{{$t('Groups')}}</span>
                </template>
                <tab-groups
                  :school="school"
                  :dreamer="dreamer"
                  @refetch-data="getData(dreamer.id)"
                  class="mt-2 pt-75"
                />
              </b-tab>
            </b-tabs>
          </b-card>
        </b-col>
      </b-row>

    </template>
    <template v-else>
      <b-alert
        variant="danger"
        :show="dreamer === undefined"
      >
        <h4 class="alert-heading">
          Error fetching dreamer data
        </h4>
        <div class="alert-body">
          No dreamer found with this id. Go back to
          <b-link
            class="alert-link"
            :to="{ name: 'schools'}"
          >
            Schools List
          </b-link>
        </div>
      </b-alert>
    </template>
  </div>
</template>

<script>
import router from '@/router'
import TabMain from './edit/TabMain.vue'
import TabUsers from './edit/TabUsers.vue'
import TabGroups from './edit/TabGroups.vue'
import CustomBreadcrumb from '@/views/components/breadcrumb/CustomBreadcrumb.vue'
import {
  BTab, BTabs, BCard, BAlert, BLink,
  BRow, BCol
} from 'bootstrap-vue'
export default {
  components:{
    CustomBreadcrumb,
    TabGroups,
    TabUsers,
    TabMain,
      BTab, BTabs, BCard, BAlert, BLink,BRow, BCol

  },
  data(){
    return {
      school: {},
      dreamer:null,
      tabIndex:null,
    }
  },
  computed:{
    breadcrumb(){
          if(this.$store.getters['user/isSchool']){
            return [
              {
                text: this.$t('Schools'),
              },
              {
                text: this.school.name
              },
              {
                to: 'school-dreamers-list',
                text: this.$t('List_model', {model: this.$t('models.dreamers')})
              },
              {
                text: this.$t('Edit'),
                active: true,
              },
            ]  
          } else{
            return [
              {
                text: this.$t('Schools'),
                to: 'schools'
              },
              {
                to: 'school-view',
                params: {
                  id: this.school.id,
                },
                text: this.school.name
              },
              {
                to: 'school-dreamers-list',
                text: this.$t('List_model', {model: this.$t('models.dreamers')})
              },
              {
                text: this.$t('Edit'),
                active: true,
              },
            ]            
          }
    }
  },
  methods:{
    async getData(id){
        console.log("loading")
        let data = {
          'id': id,
        }
        await this.$http.post('school/fetchDreamer', data).then( response =>{
          console.log(response)
          if(response.data.status == 200) {
            this.school = response.data.school;
            this.dreamer = response.data.dreamer;
            
          }
        }).catch(error => {
            console.log("Err -> ", error)
        })
    },
  },
  mounted(){
    this.id = router.currentRoute.params.dreamer_id;
    this.getData(this.id)
    console.log(router.currentRoute.params.tab)
    this.tabIndex = router.currentRoute.params.tab || 0;
  },
}
</script>

<style>

</style>