var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('fields.group'),"label-for":"group"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"multiple":"","label":"name","options":_vm.dreamer.groups},model:{value:(_vm.linked_groups),callback:function ($$v) {_vm.linked_groups=$$v},expression:"linked_groups"}})],1),_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary","type":"button","block":_vm.$store.getters['app/currentBreakPoint'] === 'xs'},on:{"click":_vm.submitChanges}},[_vm._v(" "+_vm._s(_vm.$t('forms.save_changes'))+" ")]),_c('b-button',{attrs:{"variant":"outline-secondary","type":"button","block":_vm.$store.getters['app/currentBreakPoint'] === 'xs'},on:{"click":function($event){_vm.hasHistory() 
          ? _vm.$router.go(-1) 
          : _vm.$router.push({ 
            name: 'school-dreamers-view', 
            params: { 
              id: _vm.school.id,
              dreamer_id: _vm.dreamer.id, 
            } 
          })}}},[_vm._v(" "+_vm._s(_vm.$t('forms.go_back'))+" ")])],1),_c('b-col',{attrs:{"cols":"12","lg":"6"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }